/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { makeNoise4D } from 'open-simplex-noise';

let noiseAmp = 0.7;

export function Blob({canvasContainerRef}) {
    const icosahedWireframeDetail = 5;
    const morphSpeed = 0.5;
    const noiseAmpMin = 0.08;
    const noiseAmpMax = morphSpeed;
    const hoverAnimationSpeed = 0.015;
    const rotationAnimationSpeed = 0.0015;
    const icosahedronGeometry = useRef();
    const icosahedronMesh = useRef();
    let hovered = false;
    let v3 = new THREE.Vector3();
    const noise = useMemo(() => makeNoise4D(Date.now()), []);
    const clock = useMemo(() => new THREE.Clock(), []);

    const material = useRef(new THREE.MeshLambertMaterial({ color: 'white', wireframe: true, wireframeLinewidth: 1.3 }));
    material.current.color.set('white');

    function extractSphereGeometry() {
        icosahedronGeometry.current.positionData = [];
        for (let v = 0; v < icosahedronGeometry.current.getAttribute('position').count; v++) { 
            v3.fromBufferAttribute(icosahedronGeometry.current.attributes.position, v);
            icosahedronGeometry.current.positionData.push(v3.clone());
        }
    }

    function hoverIn() {
        if (noiseAmp > noiseAmpMin) noiseAmp -= hoverAnimationSpeed;
    }

    function hoverOut() {
        if (noiseAmp < noiseAmpMax) noiseAmp += hoverAnimationSpeed;
    }

    function updateBlobGeometry() {
      const positions = icosahedronGeometry.current.attributes.position;
      const positionData = icosahedronGeometry.current.positionData;

      positionData.forEach((p, idx) => {
          const setNoise = noise(p.x, p.y, p.z, clock.getElapsedTime() * morphSpeed);
          v3.copy(p).addScaledVector(p, setNoise * noiseAmp);
          positions.setXYZ(idx, v3.x, v3.y, v3.z);
      });

      positions.needsUpdate = true;
      icosahedronGeometry.current.computeVertexNormals();
    }

    function rotateBlob() {
        icosahedronMesh.current.rotation.x += rotationAnimationSpeed;
        icosahedronMesh.current.rotation.y += rotationAnimationSpeed;
    }

    useFrame(() => {
      if (icosahedronGeometry.current) {
          if (icosahedronGeometry.current.positionData) updateBlobGeometry();
          else extractSphereGeometry();
      }
      if (hovered) hoverIn();
      else hoverOut();
      rotateBlob();
  });

    useEffect(() => {
      const handleChartPosition = () => {
        const chartContainer = canvasContainerRef.current;
        if (chartContainer) {
          const rect = chartContainer.getBoundingClientRect();
          const isPositioned = rect.top <= -90;
          hovered = isPositioned;
        }
      };
  
      window.addEventListener('scroll', handleChartPosition);
      window.addEventListener('resize', handleChartPosition);
  
      return () => {
        window.removeEventListener('scroll', handleChartPosition);
        window.removeEventListener('resize', handleChartPosition);
      };
    }, []);

  
    

  return (

      <mesh
        onPointerOver={() => hovered = true}
        onPointerOut={() => hovered = false}
        ref={icosahedronMesh}
      >
        <ambientLight intensity={1} />
        <spotLight color="white" position={[0, 0, 5]} />
        <icosahedronGeometry ref={icosahedronGeometry} args={[2, icosahedWireframeDetail]}/>
        <primitive object={material.current} attach="material" />
        </mesh>
  );
}

Blob.propTypes = {
    canvasContainerRef: PropTypes.object
};
