import GoogleMap from 'google-maps-react-markers';
import React, { useRef } from 'react';

export default function EntianGoogleMap() {

  const mapRef = useRef(null);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    renderMarkers(map, maps);
  };

  function renderMarkers(map, maps) {
    // eslint-disable-next-line no-unused-vars
    let marker = new maps.Marker({
      position: { lat: 42.67213581435475 , lng: 23.32311642543235 },
      map,
      title: 'Entian'
    });
  }

  return (
    <>
      <GoogleMap 
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        defaultZoom={15}
        defaultCenter={{ lat: 42.67213581435475 , lng: 23.32311642543235 }}
        options={{}}
        mapMinHeight={300}
        onGoogleApiLoaded={onGoogleApiLoaded}
        // onChange={(map) => console.log('Map moved', map)}
        
      >
      </GoogleMap>
    </>
  );
}
