import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import './Arrows.scss';

export const Arrows = () => <><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/>
<Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/><Arrow/>

</>;

export const TitleWithArrows = ({titleText}) => <h2 className='titleWithArrows'>
    <div className='titleArrows'><Arrows/></div>
    <p className='titleText'>
        {titleText}
    </p>
</h2>;

TitleWithArrows.propTypes = {
    titleText: PropTypes.string
  };