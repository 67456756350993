import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ScrollToPlugin, ScrollTrigger } from 'gsap/all';
import gsap from 'gsap';
import './App.scss';
import Home from './pages/Home/Home';
import OurProcess from './pages/OurProcess/OurProcess';
import OurTeam from './pages/OurTeam/OurTeam';
import YourProduct from './pages/YourProduct/YourProduct';
import GetInTouch from './pages/GetInTouch/GetInTouch';
import Navigation from './components/Navigation/Navigation';

const router = createBrowserRouter([
  {
    element: <Navigation/>,
    children: [
    {
      path: '/',
      element: <Home/>
    },
    {
      path: 'ourProcess',
      element: <OurProcess/>
    },
    {
      path: 'ourTeam',
      element: <OurTeam/>
    },
    {
      path: 'yourProduct',
      element: <YourProduct/>
    },
    {
      path: 'getInTouch',
      element: <GetInTouch/>
    }]
  }
]);



function App() {
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.config({ ignoreMobileResize: true});
  return (<RouterProvider router={router}/>);
}

export default App;
