import React from 'react';
import logo from '../../assets/EntianNavLogo.png';
import {ReactComponent as InstagramIcon} from '../../assets/socials/ig.svg';
import {ReactComponent as LinkedInIcon} from '../../assets/socials/linkedin.svg';
import {ReactComponent as MailIcon} from '../../assets/socials/mail.svg';
import {ReactComponent as LocationIcon} from '../../assets/socials/location.svg';
import './Footer.scss';
import { Link, NavLink } from 'react-router-dom';
import EntianGoogleMap from '../EntianGoogleMap/EntianGoogleMap';

export default function Footer() {
  return (
    <footer>
        <div className='footerSection'>
          <img src={logo} alt="Entian logo"/>
          <div className='socialLinks'>
            <a className='socialsLink' href="https://www.instagram.com/entiansolutions/" target="_blank" rel="noopener noreferrer">
             <InstagramIcon/>
            </a>
            <a className='socialsLink' href="https://www.linkedin.com/company/entian/" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon/>
            </a>
          </div>
        </div>
        <div className='footerSection'>
          <h2 className='headingFont'>Navigation</h2>
          <div className='links'>
          <NavLink
            to='/'
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }>
            Home
          </NavLink>
          <NavLink
            to='/ourProcess'
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }>
            Our Process
          </NavLink>
          <NavLink
            to='/ourTeam'
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }>
            Our Team
          </NavLink>
          <NavLink
            to='/yourProduct'
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }>
            Your Product
          </NavLink>
          <Link to='/getInTouch'><button>Get in Touch ➔</button></Link>
          </div>
        </div>
        <div className='footerSection'>
          <h2 className='headingFont'>Contact</h2>
          <div className='label'>
            <LocationIcon className='locationSvg'/><p>ul. &quot;Korab Planina&quot; 10, 1407 g.k. Lozenets, Sofia</p>
          </div>
            <EntianGoogleMap/>
          <div className='label'>
            <MailIcon/><p>entiansolutions@gmail.com</p>
          </div>
        </div>
    </footer>
  );
}
