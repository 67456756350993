import React from "react";
import { ReactComponent as UnderstandingIcon } from '../../assets/Your Product/grid icons/understanding.svg';
import { ReactComponent as EmpathyIcon } from '../../assets/Your Product/grid icons/empathy.svg';
import { ReactComponent as ScaleIcon } from '../../assets/Your Product/grid icons/scale.svg';
// import { ReactComponent as PmIcon } from '../../assets/Your Product/grid icons/pm1.svg';
import { ReactComponent as DeployIcon } from '../../assets/Your Product/grid icons/deploy.svg';
// import { ReactComponent as MoreIcon } from '../../assets/Your Product/grid icons/more.svg';

export const iconsArray = [
    {
      icon: (
        <div className='iconPlaceholder'>
          <UnderstandingIcon />
        </div>
      ),
      text: 'A deep understanding of your product',
    },
    {
      icon: (
        <div className='iconPlaceholder'>
          <EmpathyIcon />
        </div>
      ),
      text: 'Design, which empathizes with your users',
    },
    {
      icon: (
        <div className='iconPlaceholder'>
          <ScaleIcon />
        </div>
      ),
      text: 'A scalable, reliable and thoroughly tested system',
    },
    // {
    //   icon: (
    //     <div className='iconPlaceholder'>
    //       <PmIcon />
    //     </div>
    //   ),
    //   text: 'Project management and regular updates',
    // },
    {
      icon: (
        <div className='iconPlaceholder'>
          <DeployIcon />
        </div>
      ),
      text: 'Deployment of the product and delivering it to your users!',
    },
    // {
    //   icon: (
    //     <div className='iconPlaceholder'>
    //       <MoreIcon />
    //     </div>
    //   ),
    //   text: '& much more...',
    // },
  ];
