import React from 'react';
import './HamburgerIcon.scss'; 
import PropTypes from 'prop-types';

export default function HamburgerIcon({onClickFunc, isNavOpen, onHamburgerChange}) {
  return (
	<div className="hamburgerIcon">
		{/* 'checked === false' means it will be a hamburger. 'true' means it will be an X */}
		<input className="hamburgerIcon__cheeckbox" type="checkbox" onClick={onClickFunc} checked={isNavOpen} onChange={onHamburgerChange}/>
		<div>
			<span></span>
			<span></span>
		</div>
	</div>
  );
}

HamburgerIcon.propTypes = {
	onClickFunc: PropTypes.func,
	isNavOpen: PropTypes.bool,
	onHamburgerChange: PropTypes.func
};

