import React, { useRef } from 'react';
import { ReactComponent as Diagram } from '../../assets/ourProcess.svg';
import { Arrows } from '../../components/DesignComponents/Arrows';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import { useGSAP } from '@gsap/react';
import PropTypes from 'prop-types';

let ourProcesSteps = Object.freeze([
    <p key='1'>Get in Touch</p>,
    <p key='2'>Watch our team turn your idea into a fleshed out <strong>software product your users will love</strong></p>,
    <p key='3'>Enjoy your product while we support and maintain it.</p>,
]);

const LineWithArrows = () => <div className='lineWithArrows blurEffect'>
<Arrows/>
</div>;

export default function StepsDiagram({ isOnHomePage }) {
  const root = useRef();

  useGSAP(() => {
    if (root.current) {
      isOnHomePage ? homePageAnimation() : ownPageAnimation();
    }

  }, {scope: root.current});

  const ownPageAnimation = () => {
    // When the user clicks on 'Our Process' page, the diagram is the first thing loaded. 
    // Therefore, we want to instantly play the self-draw animation and not wait for a scroll
    // The exit, however, is still triggered by scroll, so we separate it into a ScrollTrigger
    gsap.timeline()
    .from('.lineWithArrows, .callToAction', {opacity: 0})
    .from('#ourProcessTitle', {opacity: 0})
    .from(".diagram svg path", {strokeDashoffset: 500, stagger: 0.3})
    .from(".ourProcess .line", {translateY: '-150%', stagger: 0.6}, '<=')
    .from(".processStep", {opacity: 0, stagger: 0.6}, '<=');

    //The leave-the-screen animation is still triggered by scroll
    gsap.timeline({
      scrollTrigger: {
        trigger: '.ourProcess',
        start: 'top 100px',
        end: '+=200%',
        pin: true,
        scrub: true,
      }
    })
    .to(".lineWithArrows svg", {rotate: 180})
    .to(".ourProcess", {translateX: '-100%'});
  };

  const homePageAnimation = () => {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.ourProcess',
          start: 'top 100px',
          end: '+=300%',
          pin: true,
          scrub: true,
        }
      })
      .from('.lineWithArrows, .callToAction', {opacity: 0})
      .from('#ourProcessTitle', {opacity: 0})
      .from(".diagram svg path", {strokeDashoffset: 500, stagger: 0.3})
      .from(".ourProcess .line", {translateY: '-150%', stagger: 0.6}, '<=')
      .from(".processStep", {opacity: 0, stagger: 0.6}, '<=')
      .to(".lineWithArrows svg", {rotate: 180})
      .to(".ourProcess", {translateX: '-100%', autoAlpha: 0});
  };

  return (
    <section className='ourProcessAnimationContainer pageContent'>
      <section className='ourProcess' id="ourProcess" ref={root}>
          <h2 id='ourProcessTitle'>Our Process</h2>
          <section className='diagram blurEffect'>
              <Diagram/>
          </section>
          <section className='mobileList'>
              <LineWithArrows/>
              {
                  ourProcesSteps.map((processStep, ind) => 
                  <div className='processStep' key={ind}>
                      <div className='stepNum'>{ind + 1}</div>
                      {processStep}
                      { ind < (ourProcesSteps.length - 1) ? <div className='lineContainer'><div className='line blurEffect'></div></div> : <></>}
                  </div>)
              }
          </section>
          <div className='callToAction'>
          <Link to='/getInTouch'><button>Get in Touch ➔</button></Link>
              <div className='secondaryCallToAction'>
              <p>or &nbsp; 
                  <button className='secondary'>
                  <strong>scroll to learn more about our process</strong>
                  </button>
              </p>
              <LineWithArrows/>
          </div>
          </div>
      </section>
    </section>
  );
}

StepsDiagram.propTypes = {
  isOnHomePage: PropTypes.bool
};