import React, { useRef } from 'react';
import { ourProcessText } from '../../text/OurProcessText';
import { gsap } from 'gsap';
import { TitleWithArrows } from '../../components/DesignComponents/Arrows';
import { Link } from 'react-router-dom';
import { useGSAP } from '@gsap/react';

function ProductDevelopmentCycle() {

    const root = useRef();
    const circleAnimation = useRef();

    useGSAP(() => {
      loadAnimation();
    }, {scope: root.current});

    const loadAnimation = () => {

      circleAnimation.current = gsap.timeline({
        scrollTrigger: {
            trigger: '.productDevelopmentCycle',
            start: 'top 120px',
            end: '+=200%',
            // markers: true,
            toggleActions:"play none reverse play",
            scrub: true,
            pin: true
          }});
      circleAnimation.current
      .to(".productDevelopmentCycle", {opacity: 1, translateX: '0'}, "<")
      .from(".productDevelopmentCycle .titleWithArrows svg", {rotate: '180deg'}, "<")
      .to(".circle", {translateY: 0, opacity: 1, scale: 1, stagger: 0.5})
      .to(".step", {opacity: 1, stagger: 0.5}, "<=")
      .from(".productDevelopmentCycle .line", {translateY: '-200%', stagger: 0.6}, '<=');

    };

    const circle = () => <svg height="420" width="420" className='circle'>
          <circle cx="210" cy="210" r="200" stroke="white" strokeWidth="1" fill="none" />
      </svg>;

    const shouldDisplayLine=  (index) => index < (Object.entries(ourProcessText).length - 1);

  return (
    <section ref={root} className='lifeCycleAnimationContainer pageContent'>
      <section  className='productDevelopmentCycle' id='productDevelopmentCycle'>
          <TitleWithArrows titleText='Our Product Development Life Cycle'/>
          <div className='stepsContent'>
            <div className='steps'>
              {Object.entries(ourProcessText)
              .map(([stepName, description], index) =>  
                <div key={stepName} className='step'>
                  <div className='stepNum flexContainerCentered'>{index + 1}</div>
                  <div className='stepText'>
                    <h3>{stepName}</h3>
                    <p>{description}</p>
                  </div>
                  {  shouldDisplayLine(index) ? <div className='lineContainer'><div className='line blurEffect'></div></div> : <></>}
                </div>
              )}
            </div>
            <div className='circles flexContainerCentered blurEffect colorChangeBgAnimation'>
            {circle()}{circle()}{circle()}{circle()}{circle()}
            </div>
          </div>
        </section>
        <section className='callToAction'>     
        <Link to='/getInTouch'><button>Get in Touch ➔</button></Link>
        <section className="secondaryCallToAction flexContainerCentered">
          <p>
            or &nbsp; 
            <Link to='/yourProduct'>
              <button className='secondary'><strong>see what your product can look like</strong></button>
            </Link>
          </p>
        </section>
      </section>
    </section>
  );
}

export default ProductDevelopmentCycle;
