import React, { useRef, useState } from 'react';
import './GetInTouch.scss';
import {ReactComponent as GetInTouchSVG} from '../../assets/Contact Us/GetInTouch.svg';
import { useGSAP } from '@gsap/react';
import SplitType from 'split-type';
import gsap from 'gsap';
import PropTypes from 'prop-types';

export default function GetInTouch({isOnMainPage}) {

  const [formInput, setFormInput] = useState({name: '', email: '', message: ''});
  const [result, setResult] = useState();
  const getInTouchNode = useRef();

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);
    console.log(formData);

    formData.append("access_key", process.env.REACT_APP_WEB3FORMS_API_KEY);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  useGSAP(() => {
    let titleText = document.getElementById('titleText');
    new SplitType(titleText, { types: 'char'});

    gsap.timeline({
      scrollTrigger: {
        trigger: getInTouchNode.current,
        start: 'top bottom',
        end: isOnMainPage ? 'center center' : undefined,
        toggleActions: 'play none none play',
        scrub: isOnMainPage ? 1 : undefined,
        // markers: true
      }
    })
    .to('#titleText .char', {y: 0, duration: 0.5, stagger: 0.03, delay: 0})
    .to('.right svg', {strokeDashoffset: 0, duration: 1});
  }, { scope: getInTouchNode.current});

  return (
    <section className='getInTouch flexContainerCentered' ref={getInTouchNode}>
      <div className='left'>
          <h1 className='headingFont' id='titleText'>Let`s <span className='accentText blurEffect'>get in touch</span>!</h1>
        <form className='contactForm' onSubmit={onSubmit}>
          <label><div className='blurEffect'>Name</div>
            <input type="text" name="name" value={formInput.name} onChange={(event) => setFormInput(prevState => ({...prevState, name: event.target.value}))} />
          </label>
          <label><div className='blurEffect'>Email</div>
            <input required type="email" name="email" value={formInput.email} onChange={(event) => setFormInput(prevState => ({...prevState, email: event.target.value}))} />
          </label>
          <label><div className='blurEffect'>Tell us about your idea...</div>
            <textarea  
              type="text"
              name="message"  
              rows='6'
              required
              value={formInput.message} 
              onChange={(event) => setFormInput(prevState => ({...prevState, message: event.target.value}))} />
          </label>
          <button type='submit'>Send</button>
          {result}
        </form>
      </div>
      <div className='right blurEffect'>
        <GetInTouchSVG/>
      </div>
    </section>
  );
}

GetInTouch.propTypes = {
  isOnMainPage: PropTypes.bool
};
