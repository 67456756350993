import React, { useRef } from 'react';
import OurProcess from '../OurProcess/OurProcess';
import YourProduct from '../YourProduct/YourProduct';
import './Home.scss';
import { Blob } from './Blob';
import { Canvas } from '@react-three/fiber';
import gsap from 'gsap';
import {ReactComponent as InstagramIcon} from '../../assets/socials/ig.svg';
import {ReactComponent as LinkedInIcon} from '../../assets/socials/linkedin.svg';
import {ReactComponent as MailIcon} from '../../assets/socials/mail.svg';
import { Link } from 'react-router-dom';
import { useGSAP } from '@gsap/react';
import SplitType from 'split-type';
import GetInTouch from '../GetInTouch/GetInTouch';

function Home() {
  const root = useRef();

  useGSAP(() => {
    let homeTimeline = gsap.timeline();

    homeTimeline
    .from(".text>span", { opacity: 0, y: -100, duration: 1, stagger: 0.5, ease: "power4"})
    .from(".text button", { opacity: 0, y: 150, duration: 0.5},'<');
  }, {scope: root.current});


  return (
    <section className='home'>
      {/* Out of flow placement */}
      <div className='socials blurEffect flexContainerCentered'>
        <div className='line'></div>
        <Link className='socialsLink' to='/getInTouch'>
          <MailIcon/>
        </Link>
        <a className='socialsLink' href="https://www.instagram.com/entiansolutions/" target="_blank" rel="noopener noreferrer">
         <InstagramIcon/>
        </a>
        <a className='socialsLink' href="https://www.linkedin.com/company/entian/" target="_blank" rel="noopener noreferrer">
          <LinkedInIcon/>
        </a>
        <div className='line invisible'></div>
      </div>
      {/* --------- */}
      <div className='text' ref={root}>
        <span className='topLine'>Helping <span className='accentText'>you</span> shape tomorrow.</span>
        <span className='bottomLine'>Discover the full potential of your vision with our proven expertise in delivering software solutions that redefine excellence.</span>
        <Link to='/getInTouch'><button>Get in Touch ➔</button></Link>
      </div>
      <BigText/>
      <YourProduct animateOnScrollTrigger/>
      <OurProcess animateOnScrollTrigger/>
      <GetInTouch isOnMainPage/>
    </section>
  );
}

export default Home;

function BigText() {
  
  const canvasContainerRef = useRef();
  const root = useRef();
  
  useGSAP(() => {
    let bigWords = document.getElementsByClassName('bigWord');
    for (let bigWord of bigWords) {
      new SplitType(bigWord, {types: 'char'});
    }
    gsap.from("#canvas-container", { opacity: 0, duration: 3, ease: "power4" });

    let animationTimeline = gsap.timeline({ 
      paused: true,
      scrollTrigger: {
      trigger: canvasContainerRef.current,
      start: 'top top',
      end: '+=250%',
      // markers: true,
      toggleActions:"play none reverse play",
      scrub: true,
      pin: true
    }});
    animationTimeline.from('.makingYourProduct', {opacity: 0, y: '-100%'});
    
    animationTimeline
    .from('.underline', {x: '150%', opacity: 0, duration: 1, stagger: 2})
    .to('.intuitive .char', {y: 0, duration: 1, stagger: 0.1}, '<')
    .to('.scalable .char', {y: 0, duration: 1, stagger: 0.1}, '2')
    .to('.reliable .char', {y: 0, duration: 1, stagger: 0.1}, '4');
  }, {scope: canvasContainerRef.current});
  
return (
  <div className='image' ref={root}>
    <div 
      id="canvas-container" 
      className={screen.width > 800 ? 'blurEffect colorChangeBgAnimation' : ''}
      style={{width: '100%', height: '130vh'}} 
      ref={canvasContainerRef}> 
      <div className='bigText'>
        <div className='makingYourProduct blurEffect'>Making your product...</div>
        <div className='bigWord intuitive blurEffect'>intuitive</div>
        <div className='underline blurEffect'/>
        <div className='bigWord scalable blurEffect'>scalable</div>
        <div className='underline two blurEffect'/>
        <div className='bigWord reliable blurEffect'>reliable</div>
        <div className='underline three blurEffect'/>
      </div>
      <Canvas>
        <Blob canvasContainerRef={canvasContainerRef}/>
      </Canvas>
    </div>
  </div>
);
}
  