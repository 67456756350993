import React, { useRef } from 'react';
import './YourProduct.scss';
import { iconsArray } from './iconsArray';
import gsap from 'gsap';
import PropTypes from 'prop-types';
// import { typesOfSoftware } from '../../text/icons';
import { Link } from 'react-router-dom';
import { useGSAP } from "@gsap/react";
// import { TitleWithArrows } from '../../components/DesignComponents/Arrows';

export default function YourProduct({animateOnScrollTrigger}) {

  const items = useRef(iconsArray);
  const root = useRef();

  useGSAP(() => {
    loadAnimation();
  }, { scope: root.current });

  const loadAnimation = () => {

    let whatDoWeOfferTl = gsap.timeline(
      animateOnScrollTrigger ? 
      {
      scrollTrigger: {
          trigger: root.current,
          start: 'top 90px',
          end: '+=100%%',
          toggleActions:"play none reverse play",
          pin: true,
          scrub: 1,
          pinSpacing: true
        }
      } 
      : 
      {}
    );
    whatDoWeOfferTl.from('.whatDoWeOffer', {translateX: '200%', autoAlpha: 0})
    .from('.item', {translateX: '100vw', stagger: 0.1, autoAlpha: 0, duration: 1})
    .from('.item .iconPlaceholder', {scale: '0.3', stagger: 0.1}, '<');

    // let kindsOfAppsTl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.kindsOfSoftware',
    //     start: 'top center',
    //     end: 'bottom bottom',
    //     markers: true,
    //     toggleActions:"play none reverse",
    //   }
    // });
    // kindsOfAppsTl.from('.titleWithArrows', {translateX: '-50vw', opacity:0});

  };

  return (
    <section className='yourProductAnimationContainer flexContainerCentered colorChangeBgAnimation detached'  ref={root}>
      <div className='sectionContainer'>
        <section className='yourProductSection pageContent'>
          <div className='hook whatDoWeOffer'>
            <h2>What do we offer?</h2>
            <div className='answerText'>
              <h1 className='software headingFont accentText'>Software</h1><p className='smallFont'>and by software we mean...</p>
            </div>
          </div>
          <section className='items'>
            {
              items.current && items.current.map((item) => 
                <div className='item card' key={item.text}>
                  {item.icon}
                  <p>{item.text}</p>
                </div>
              )
            }
          </section>
        </section>
      </div>
      {
          !animateOnScrollTrigger ? 
          <div className='callToAction'>
            <p>We would love to hear about your idea!</p>
            <Link to='/getInTouch'><button>Get in Touch ➔</button></Link>
          </div>
        //   <div className='sectionContainer'>
        //     <section className='yourProductSection pageContent kindsOfSoftware'>
        //       <div className='title'>
        //        <TitleWithArrows titleText={'What kind of software?'}/>
        //       </div>
        //       <div className='list flexContainerCentered'>
        //         {
        //           typesOfSoftware.map((type) => 
        //           <div className='kindOfSoftwareCard flexContainerCentered' key={type.label}>
        //             <p>{type.label}</p>
        //             <div className='imagePlaceholder flexContainerCentered blurEffect'>
        //               {type.icon}
        //             </div>
        //             {/* <button className='secondary'>
        //               <strong>see technologies</strong>
        //             </button> */}
        //           </div>
        //           )
        //         }
        //         </div>
        //         <section className='callToAction'>
        //         <Link to='/getInTouch'><button>Get in Touch ➔</button></Link>
        //         </section>
        //     </section>
        //   </div>
        :
        <></>
        }
    </section>

  );
}

YourProduct.propTypes = {
  animateOnScrollTrigger: PropTypes.bool
};

YourProduct.defaulPropTypes = {
  animateOnScrollTrigger: false
};

