import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import './Navigation.scss';
import logo from '../../assets/EntianNavLogo.png';
// import FPSStats from 'react-fps-stats';
import gsap from 'gsap';
import HamburgerIcon from '../DesignComponents/HamburgerIcon';
import Footer from '../Footer/Footer';

export default function Navigation() {

  const [isNavOpen, setIsNavOpen] = useState(false);

  let location = useLocation();
  let linksMenu = useRef();

  useEffect(() => {
    gsap.to(window, {duration: 0.3, scrollTo: 0});
    if (!linksMenu.current.classList.contains("hidden") && window.innerWidth <= 768) {
      linksMenu.current.classList.add("hidden");
      if (isNavOpen) setIsNavOpen(false);
    }
  }, [location]);

  const hamburgerClick = () => {
    linksMenu.current.classList.toggle('hidden');
  };

  return (
    <>
    <div className='background'></div>
    <nav>
      {/* <FPSStats/> */}
        <Link to='/'><img src={logo}/></Link>
        <HamburgerIcon onClickFunc={hamburgerClick} isNavOpen={isNavOpen} onHamburgerChange={() => setIsNavOpen(!isNavOpen)}/>
        <div className='links hidden' ref={linksMenu}>
        <NavLink
         to='/'
         className={({ isActive, isPending }) =>
         isPending ? "pending" : isActive ? "active" : ""
       }>
        Home
       </NavLink>
       <NavLink
         to='/ourProcess'
         className={({ isActive, isPending }) =>
         isPending ? "pending" : isActive ? "active" : ""
       }>
        Our Process
       </NavLink>
       <NavLink
         to='/ourTeam'
         className={({ isActive, isPending }) =>
         isPending ? "pending" : isActive ? "active" : ""
       }>
        Our Team
       </NavLink>
       <NavLink
         to='/yourProduct'
         className={({ isActive, isPending }) =>
         isPending ? "pending" : isActive ? "active" : ""
       }>
        Your Product
       </NavLink>
       <NavLink
         to='/getInTouch'
         className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
       }>
        Get in Touch
       </NavLink>
        </div>
    </nav>
    <Outlet />
    <Footer/>
    </>
  );
}
