import React from 'react';
import JIRAICON from '../assets/OurTeam/BusinessAnalyst/jira.png';
import TRELLOICON from '../assets/OurTeam/BusinessAnalyst/trello.png';
import SLACKICON from '../assets/OurTeam/BusinessAnalyst/slack.png';
import GITLABICON from '../assets/OurTeam/BusinessAnalyst/gitlab.png';
import ADOBEXDICON from '../assets/OurTeam/UIUXDesigner/adobeXd.png';
import ADOBEAIICON from '../assets/OurTeam/UIUXDesigner/AdobeIllustrator.png';
import PHOTOSHOPICON from '../assets/OurTeam/UIUXDesigner/adobePhotoshop.png';
import JAVASCRIPTICON from '../assets/OurTeam/BackEndAndFronEnd/javaScript.png';
import TYPESCRIPTICON from '../assets/OurTeam/BackEndAndFronEnd/typeScipt.png';
import EXPRESSICON from '../assets/OurTeam/BackEndAndFronEnd/express.png';
import NODEJSICON from '../assets/OurTeam/BackEndAndFronEnd/node.png';
import MYSQLICON from '../assets/OurTeam/BackEndAndFronEnd/mySql.png';
import POSTGRESICON from '../assets/OurTeam/BackEndAndFronEnd/postgres.png';
import REACTICON from '../assets/OurTeam/BackEndAndFronEnd/react.png';
import SASSICON from '../assets/OurTeam/BackEndAndFronEnd/sass.png';
import KUBERENETESICON from '../assets/OurTeam/DevOps/kubernetes.png';
import AWSICON from '../assets/OurTeam/DevOps/aws.png';
import TERRAFORMICON from '../assets/OurTeam/DevOps/terraform.png';
import ANSIBLEICON from '../assets/OurTeam/DevOps/ansible.png';
import JENKINSICON from '../assets/OurTeam/DevOps/jenkins.png';
import GITLABCICDICON from '../assets/OurTeam/DevOps/gitlab.png';
import SELENIUMICON from '../assets/OurTeam/QA/selenium.png';
import TESTNGICON from '../assets/OurTeam/QA/testng.png';
import CUCUMBERICON from '../assets/OurTeam/QA/cucumber.svg';
import POSTMANICON from '../assets/OurTeam/QA/postman.png';
import { ReactComponent as MobileIcon } from '../assets/Your Product/grid icons/mobile.svg';
import { ReactComponent as WebAppsIcon } from '../assets/Your Product/grid icons/web1.svg';
import { ReactComponent as AndMoreIcon } from '../assets/Your Product/grid icons/more.svg';

const ICONS = Object.freeze({
  JIRA: {
    icon: JIRAICON,
    name: 'Jira'
  },
  TRELLO: {
    icon: TRELLOICON,
    name: 'Trello'
  },
  SLACK: {
    icon: SLACKICON,
    name: 'Slack'
  },
  GITLAB: {
    icon: GITLABICON,
    name: 'Gitlab'
  },
  ADOBEXD: {
    icon: ADOBEXDICON,
    name: 'AdobeXD'
  },
  ADOBEAI: {
    icon: ADOBEAIICON,
    name: 'Adobe Illustrator'
  },
  PHOTOSHOP: {
    icon: PHOTOSHOPICON,
    name: 'Adobe Photoshop'
  },
  JAVASCRIPT: {
    icon: JAVASCRIPTICON,
    name: 'JavaScript'
  },
  TYPESCRIPT: {
    icon: TYPESCRIPTICON,
    name: 'TypeScript'
  },
  EXPRESS: {
    icon: EXPRESSICON,
    name: 'Express'
  },
  NODEJS: {
    icon: NODEJSICON,
    name: 'NodeJS'
  },
  MYSQL: {
    icon: MYSQLICON,
    name: 'MySql'
  },
  POSTGRES: {
    icon: POSTGRESICON,
    name: 'Postgres'
  },
  REACT: {
    icon: REACTICON,
    name: 'React'
  },
  SASS: {
    icon: SASSICON,
    name: 'Sass'
  },
  KUBERNETES: {
    icon: KUBERENETESICON,
    name: 'Kubernetes'
  },
  AWS: {
    icon: AWSICON,
    name: 'AWS'
  },
  TERRAFORM: {
    icon: TERRAFORMICON,
    name: 'Terraform'
  },
  ANSIBLE: {
    icon: ANSIBLEICON,
    name: 'Ansible'
  },
  JENKINS: {
    icon: JENKINSICON,
    name: 'Jenkins'
  },
  GITLABCICD: {
    icon: GITLABCICDICON,
    name: 'Gitlab CI/CD'
  },
  SELENIUM: {
    icon: SELENIUMICON,
    name: 'Selenium'
  },
  TESTNG: {
    icon: TESTNGICON,
    name: 'Testng'
  },
  CUCUMBER: {
    icon: CUCUMBERICON,
    name: 'Cucumber'
  },
  POSTMAN: {
    icon: POSTMANICON,
    name: 'Postman'
  }
});

export const positions = [
    {
      positions: [
      {
        title: 'Business Analyst',
        text: `Gathers all information needed for a deep understanding of your product - requirements, pain points and stakeholders. They make sure that the generated product will meet your vision and will integrate your business logic.`,
      },
      {
        title: 'Project Manager',
        text: `Takes care of the overall project organization- client communication, team meetings based on SCRUM, creates tasks schedule, adjusts deadlines, creates the stories/improvements/tasks in the chosen PM tool.`,
      }
    ],
      icons: [ICONS.JIRA, ICONS.TRELLO, ICONS.SLACK, ICONS.GITLAB]
    },
    {
      positions: [
      {
        title: 'UI/UX Designer',
        text: `Builds your product's visual identity. Studies the users to make sure that the interface is intuitive and easy to use. Ensures that all the requirements are reflected.`,
      }
    ],
    icons: [ICONS.ADOBEXD, ICONS.ADOBEAI, ICONS.PHOTOSHOP]
  },
    {
      positions: [
      {
        title: 'Back End Developer',
        text: `Makes sure to implement all your business logic having the specified requirements which could also include - design database architecture wisely, resolving complex use cases, integrating 3rd party systems, etc.`
      },
      {
        title: 'Front End Developer',
        text: `Implements the end user version of your product following the given design and general guidelines for introducing a fast, smooth and efficient user experience of the product.`,
      }
    ],
    icons: [ICONS.EXPRESS, ICONS.NODEJS, ICONS.MYSQL, ICONS.POSTGRES, ICONS.JAVASCRIPT, ICONS.TYPESCRIPT, ICONS.REACT, ICONS.SASS]
  },
    {
      positions: [
      {
        title: 'DevOps',
        text: `Always keeping all systems alive and is part of all levels of the project's lifecycle - from building development, testing & production environments through implementing scalable and easily maintainable solutions to meet your project's needs.`,
      }
    ],
    icons: [ICONS.KUBERNETES, ICONS.AWS, ICONS.TERRAFORM, ICONS.ANSIBLE, ICONS.JENKINS, ICONS.GITLABCICD]
  },
    {
      positions: [
      {
        title: 'QA',
        text: `Keeps the product quality on track. QAs help identify different kinds of undesired behaviour during (and after) development stages using various techniques as manual testing, automation testing, security testing, performance & penetration testing, etc.`,
      }
    ],
    icons: [ICONS.SELENIUM, ICONS.TESTNG, ICONS.CUCUMBER, ICONS.POSTMAN]
  },
  ];

  export const typesOfSoftware = [
    {
      label: 'Mobile Apps',
      icon: <MobileIcon/>,

    },
    {
      label: 'Web Apps',
      icon: <WebAppsIcon/>,
      
    },
    {
      label: 'And more...',
      icon: <AndMoreIcon/>,
      
    }
  ];
