import React, { useRef } from 'react';
import './OurTeam.scss';
import { positions } from '../../text/icons';
import { TitleWithArrows } from '../../components/DesignComponents/Arrows';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

export default function OurTeam() {

  const root = useRef();

  useGSAP(() => {
    let contentTl = gsap.timeline();
    contentTl
    .from('.positions .animationContainer .group', { translateY: '-150%', duration: 0.5, stagger: 0.5 })
    .to('.groupPositionsText', {opacity: 1, duration: 0.5, stagger: 0.5}, '0.5')
    .from('.group .icons p', {opacity: 0, duration: '0.5'}, '0.7')
    .from('.group .icons .icon', {opacity: 0, translateX: '50vw', stagger: 0.1}, '1');
  }, {scope: root.current});

  return (
    <section className='ourTeam pageContent' ref={root}>
      <div className='title'>
        <TitleWithArrows titleText={'Our Team'}/>
      </div>
      <section className='positions'>
        {
          positions && positions.map((group, ind) => 
            <div className='animationContainer' key={ind}>
              <div className='group' >
                <section className='groupPositionsText'>
                  {group.positions.map((pos) => 
                      <section key={pos.title} className='position'>
                        <p className='positionTitle'><strong>{pos.title}</strong></p>
                        <p className='bigFont'>
                          {pos.text}
                        </p>
                      </section>
                  )}
                </section>
                <div className='icons'>
                  <p><strong>Technologies:</strong></p>
                  {group.icons.map((icon, ind) => 
                    <div key={ind} className="icon">
                      <img src={icon.icon}/>
                      <sub>{icon.name}</sub>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }
      </section>
    </section>
  );
}
