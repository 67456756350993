let ourProcessText = Object.freeze({
    'Analysis & Eliciting Requirements': 
        'Gain a deep understanding of your idea - identifying requirements, pain points and stakeholders.',
    'Design & Prototyping': 
        'Defining your system’s architecture, its components and how to present it to your end users in a \
        way that is intuitive, accessible and aligned with your brand identity.',
    'Development': 
        'This is when we write code and your system’s features come to life!',
    'Deployment & Testing': 
        'The improvements made by the developers go live! :) But it is not released to \
        your end users yet :( Only after our QAs test it to make sure everything is working correctly! :)',
    'Maintenance & Support': 
        'In case you would like to expand your product, or maybe your users have given \
        you feedback on feature improvements, we will take care of it. How? Go back to step 1 :)',
});

export {
    ourProcessText
};
