import React from 'react';
import './OurProcess.scss';
import ProductDevelopmentCycle from './ProductDevelopmentCycle';
import PropTypes from 'prop-types';
import StepsDiagram from './StepsDiagram';

function OurProcess({animateOnScrollTrigger}) {
  
  return (
    <>
      <StepsDiagram isOnHomePage={animateOnScrollTrigger}/>
      <ProductDevelopmentCycle />
    </>
  );
}

OurProcess.propTypes = {
  animateOnScrollTrigger: PropTypes.bool
};

OurProcess.defaultProps = {
  animateOnScrollTrigger: false
};

export default OurProcess;
